.title_container{
  justify-content: center;
  align-items: center;
  display: flex;
}
.title {
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  line-height: 1.5;
  font-size: 4rem;
  text-transform: uppercase;
  text-align: center;
  align-self:center;
  font-family: 'Montserrat', sans-serif;
}

.title span:first-of-type {
  text-transform: none;
  font-size: 1.5rem;
}

.title span:nth-of-type(2) {
  font-size: calc(var(--heading-size)*2rem);
  
}

.title span:nth-of-type(3) {
  font-size: calc(var(--heading-size)*2rem*0.5);
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
}

.tagline {
  /* text-transform: uppercase; */
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  letter-spacing: 2.6px;
  font-size: 1rem;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.flex{
  display: flex;
}
.row {
  flex-direction: column;
}

@media (min-width: 880px){
  .title{
    line-height: 1;
  }
  .row, .tagline {
    flex-direction: row;
  }
  .title{
    text-align: left;
  }
  .tagline {
    font-size: 2rem;
  }

  .tagline span{
    margin-left: 10px;
  }

}
