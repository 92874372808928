:root {
  --heading-size: 1;
  --container-padding: 10px;
}

@media (min-width: 375px){
  :root{
    --heading-size: 1;
  }
}

@media (min-width: 440px){
  :root {
    --heading-size: 1.5;
  } 
}

@media (min-width: 768px){
  :root {
    --heading-size: 3;
  }
}
html {
  color-scheme: dark;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

body {
      color: white;
      background-color: rgb(40, 40, 45);
    }

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

header{
  position: -webkit-sticky;
  position: sticky;
  z-index: 30;
  top: 0;
}

// @media (prefers-color-scheme: dark) {
//   html {
//     color-scheme: dark;
//   }
//   body {
//     color: white;
//     //background: black;
//     background-color: rgb(40, 40, 45);
//   }
// }

.container {
  padding: 0 0;
}

.main {
  min-height: 100vh;
  padding: 4rem 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav{
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
}
.nav__menu-bar{
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  cursor: pointer;
}
.nav__menu-bar div{
  width: 40px;
  height: 4px;
  background-color: white;
  border-radius: 2px;
}
.nav__menu-list{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100px;
  width: 288px;
  row-gap: 24px;
  left: -288px;
  padding: 24px 16px;
  transition: all 0.2s;
  //min-height: calc(100vh - 60px);
  background-color: rgb(40, 40, 45);
}
.nav__menu-list.active{
  left: 20px;
}
.nav__link{
  font-size: 18px; 
  position: relative;
  transition: all 0.2s;
}

.nav__link:hover{
 font-weight: bold;
}

.nav__item {
  cursor: pointer;
}

.center{
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .nav{
    padding: 16px 10rem;
  }
  .nav__menu-bar{
    display: none;
  }
  .nav__menu-list{
    position: unset;
    flex-direction: row;
    min-height: fit-content;
    width: fit-content;
    column-gap: 24px;
    align-items: center;
  }
  .nav__link::before{
    content: '';
    position: absolute;
    width: 0%;
    height: 6px;
    bottom: -16px;
    left: 0;
    transition: all 0.2s;
  }
  
  .nav__link:hover:before{
   width: 100%;
  }
}

.star{
  z-index: -1;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 1px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 30px 50px 3px 10px #fff,
                -30px -70px 1px 10px #fff;
}

.star-bg{
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
  position: absolute;
}
.moon{
  position: absolute;
  top: 15%;
  right: 25%;
  background: #F6EDBD;
  box-shadow: 0 0 5rem .75rem #f6edbd, 0 0 3rem .75rem #ece1a8 inset;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  z-index: -99;
  animation: moonMovement 25s linear infinite;
}

@keyframes moonMovement {
  0% { transform: rotate(0deg) translateX(20px) scale(0.5) rotate(0deg); }
  50%   { transform: rotate(180deg) translateX(20px) scale(0.8) rotate(-180deg); }
  100%   { transform: rotate(360deg) translateX(20px) scale(0.5) rotate(-360deg); }
}

@for $i from 0 through 7 {
  .crater--#{$i} {
      position: absolute;
      background: #ece1a8;
  }
}

.crater {
  &--0 {
        top: 1.2rem;
        left: 1.4rem;
        border-radius: 42%;
        transform: rotate(-30deg);
        height: 1.1rem;
        width: 3rem;
    }
  &--1 {
    top: 1.6rem;
    left: 1.4rem;
    background: #F6EDBD;
    border-radius: 42%;
    box-shadow: inset -2px 4px 4px 0px #ece1a8;
    transform: rotate(-40deg);
    height: 1.1rem;
    width: 3.7rem;
  }

  &--2 {
    top: 2rem;
    left: 3.2rem;
    border-radius: 48%;
    box-shadow: 0 2px 4px 0px #ece1a8;
    height: 1.9rem;
    width: 2.1rem;
  }

  &--3 {
    top: 1rem;
    left: 4.3rem;
    border-radius: 48%;
    box-shadow: 0 -2px 4px 0px #ece1a8;
    height: 1.6rem;
    width: 2.4rem;
  }

  &--4 {
    top: 5rem;
    left: 0.8rem;
    border-radius: 48%;
    height: 3.6rem;
    width: 5.1rem;
  }

  &--5 {
    top: 5rem;
    left: 0.7rem;
    background: #F6EDBD;
    box-shadow: inset 0 -5px 8px 0px #ece1a8;
    border-radius: 38%;
    transform: rotate(15deg);
    height: 3rem;
    width: 6.4rem;
  }

  &--6 {
    top: 4rem;
    left: 1rem;
    background: #F6EDBD;
    border-radius: 42%;
    height: 3rem;
    width: 3rem;
  }

  &--7 {
    top: 4rem;
    left: 1rem;
    border-radius: 42%;
    box-shadow: 4px 3px 6px 3px #ece1a8;
    height: 3rem;
    width: 3.2rem;
  }
}

footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
}